<template>
  <div class="tips-free">
    <TipsInfo v-if="!showDesglose" @show="show" />
    <TipsDesglose v-if="showDesglose" @show="show" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'ParlayFree',
  components: {
    TipsInfo: () => import('@/components/Sections/GolTipster-v2/Tips/TipsInfo'),
    TipsDesglose: () => import('@/components/Sections/GolTipster-v2/Tips/TipsDesglose'),
  },
  data() {
    return { showDesglose: false };
  },
  computed: {
    ...mapState(['displayWidth']),
    isMobile() {
      return this.displayWidth < 464;
    },
  },
  watch: {},
  methods: {
    ...mapActions('betsGeneral', ['getTipsFree']),
    show() {
      this.showDesglose = !this.showDesglose;
    },
  },
  async created() {
    await this.getTipsFree();
  },
};
</script>

<style scoped lang="scss"></style>
